import { ALERT_MESSAGE } from '../utils/constants'

export default {
  queryAPI: (query) => {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          query: query,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.errors) reject(response.errors[0].message)
          else resolve(response.data)
        })
        .catch((e) => {
          reject(ALERT_MESSAGE.error + ': ' + e)
        })
    })
  },

  queryFunctions: (functionName, code, body) => {
    return new Promise(() => {
      fetch(`https://${process.env.REACT_APP_AZURE_FUNCTION_NAME}.azurewebsites.net/api/${functionName}?code=${code}`, {
        method: 'POST',
        body: JSON.stringify(body),
      })
    })
  },
}
